<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="black">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#13736f"
      spinner="spinner"
    />
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap pr-1 pt-7 justify-end>
          <v-flex xs2 pb-5>
            <v-btn
              class="no-uppercase"
              dark
              width="160px"
              color="#13736f"
              @click="dialog2 = true"
              ><span
                style="
                  font-family: mainfont;
                  font-size: 15px;
                  color: white;
                  letter-spacing: 1px;
                  cursor: pointer;
                "
                >Add
              </span>
              <v-icon dark size="15px"> mdi-plus </v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout px-5 justify-center wrap>
          <v-flex
            xs12
            lg4
            align-self-center
            pa-2
            v-for="(item, i) in list"
            :key="i"
          >
            <v-card elevation="0" height="500px">
              <v-layout wrap justify-center>
                <v-flex xs12 pl-3 pr-3 pt-3 align-self-center>
                  <v-img
                 height="200px"
                 contain
                    @click="$router.push('/NewsDetailedView?id=' + item._id)"
                    :src="mediaURLnew + item.photo"
                  ></v-img>
                </v-flex>

                <v-flex pl-3 pt-2 xs12 align-self-center>
                  <span
                    class="mainfont"
                    style="
                      color: #757575;
                      font-size: 12px;
                      font-weight: lighter;
                    "
                    >{{ formatDate(item.create_date) }}</span
                  >
                </v-flex>
                <v-flex pb-2 pt-1 xs11 align-self-center>
                  <span
                    class="mainfont"
                    style="color: black; font-size: 18px; font-weight: bold"
                    >{{ item.title }}</span
                  >
                </v-flex>

                <v-flex px-4 xs12 align-self-center class="justified-text">
                  <span
                    v-html="item.content"
                    class="mainfont"
                    style="color: #757575; font-size: 12px; font-weight: 400px"
                  ></span>
                </v-flex>
                <v-flex xs12 pb-2 align-self-center>
                  <v-layout wrap fill-height justify-end>
                    <v-flex xs2 pl-2 text-left>
                      <v-btn
                        class="mx-2"
                        fab
                        small
                        elevation="0"
                        color="#EFFFFA"
                        @click="(editdailog = true), (curid = item)"
                      >
                        <v-icon color="black" size="130%" dark>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </v-flex>
                    <v-flex xs2 text-left>
                      <v-btn
                        class="mx-2"
                        small
                        fab
                        elevation="0"
                        color="#EFFFFA"
                        @click="(deletedialog = true), (curid = item._id)"
                      >
                        <v-icon color="red" dark size="130%">
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-dialog
        :retain-focus="true"
        persistent
        v-model="deletedialog"
        max-width="600px"
      >
        <v-card>
          <v-card-title
            class="mainfont"
            style="color: black; font-size: 18px; font-weight: lighter"
            >Are you sure you want to delete this News?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="black"
              text
              @click="deletedialog = false"
              class="mainfont"
              >Cancel</v-btn
            >
            <v-btn color="red" class="mainfont" text @click="deleteItem()"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="editdailog"
        :retain-focus="true"
        persistent
        max-width="950px"
      >
        <v-card >
          <v-layout pa-4 wrap justify-center>
            <v-flex
              ><v-layout wrap justify-center>
                <v-flex xs12 text-right>
                  <v-icon
                    @click="editdailog = false"
                    color="red"
                    size="150%"
                    dark
                  >
                    mdi-close
                  </v-icon>
                </v-flex>
                <v-flex xs10 class="mainfont">
                  <span
                    class="mainfont"
                    style="color: black; font-size: 16px; font-weight: bold"
                    >Title</span
                  >
                  <v-form @submit.prevent="validateInput">
                    <v-text-field
                      color="black"
                      outlined
                      background-color="white"
                      dense
                      type="text"
                      v-model="curid.title"
                      hide-details
                    >
                    </v-text-field>
                  </v-form>
                </v-flex>

                <v-flex pt-2 xs10 class="mainfont">
                  <span
                    class="mainfont"
                    style="color: black; font-size: 16px; font-weight: bold"
                    >Content</span
                  >
                  <v-form @submit.prevent="validateInput">
                    <vue-editor v-model="curid.content"></vue-editor>
                  </v-form>
                </v-flex>
                <v-flex xs10 pt-2>
                  <span
                    class="mainfont"
                    style="color: black; font-size: 16px; font-weight: bold"
                    >From Date</span
                  >
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        clearable
                        dense
                        outlined
                        readonly
                        v-model="curid.fromDate"
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="from = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="curid.fromDate"
                      @change="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs10>
                  <span
                    class="mainfont"
                    style="color: black; font-size: 16px; font-weight: bold"
                    >To Date</span
                  >
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        clearable
                        readonly
                        dense
                        outlined
                        v-model="curid.toDate"
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="from = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="curid.toDate"
                      @change="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs10
                  style="color: black; font-size: 16px; font-weight: bold"
                  class="mainfont"
                >
                  <ImageComp
                    :singleImage="curid.photo"
                    @stepper="winStepper"
                    :height="'800'"
                    :width="'1300'"
                    :heading="'Upload Image'"
                    :componentType="'topImage'"
                  />
                </v-flex>

                <v-flex xs10 pt-3 text-right>
                  <v-btn
                  class="no-uppercase"
                    dark
                    width="100px"
                    color="#13736f"
                    @click="edit(curid._id)"
                  >
                    <span
                      class="mainfont"
                      style="color: white; font-size: 13px"
                    >
                      Edit
                    </span>
                    <v-icon size="15px" color="white">mdi-pencil</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
      <v-dialog
        :retain-focus="true"
        persistent
        v-model="dialog2"
        max-width="950px"

      >
        <v-card >
          <v-layout wrap justify-center>
            <v-flex
              ><v-layout wrap justify-center pa-3>
                <v-flex xs12 text-right>
                  <v-icon @click="dialog2 = false" color="red" size="150%" dark>
                    mdi-close
                  </v-icon>
                </v-flex>
                <v-flex xs10>
                  <span
                    class="mainfont"
                    style="color: black; font-size: 16px; font-weight: bold"
                    >Title</span
                  >
                  <v-form @submit.prevent="validateInput">
                    <v-text-field
                      color="black"
                      outlined
                      background-color="white"
                      dense
                      type="text"
                      v-model="title"
                      hide-details
                    >
                    </v-text-field>
                  </v-form>
                </v-flex>

                <v-flex pt-2 xs10>
                  <span
                    class="mainfont"
                    style="color: black; font-size: 16px; font-weight: bold"
                    >Content</span
                  >
                  <vue-editor v-model="content"></vue-editor>
                </v-flex>

                <v-flex xs10 pt-2>
                  <span
                    class="mainfont"
                    style="color: black; font-size: 16px; font-weight: bold"
                    >From Date</span
                  >
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        clearable
                        dense
                        outlined
                        readonly
                        v-model="fromDate"
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="from = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fromDate"
                      @change="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs10>
                  <span
                    class="mainfont"
                    style="color: black; font-size: 16px; font-weight: bold"
                    >To Date</span
                  >
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        clearable
                        readonly
                        dense
                        outlined
                        v-model="toDate"
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="from = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="toDate"
                      @change="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>

                <v-flex
                  xs10
                  pt-1
                  class="mainfont"
                  style="color: black; font-size: 16px; font-weight: bold"
                >
                  <ImageComp
                    @stepper="winStepper"
                    :height="'800'"
                    :width="'1300'"
                    :heading="'Upload Image'"
                    :componentType="'topImage'"
                  />
                </v-flex>

                <v-flex xs10 text-right pt-3>
                  <v-btn
                    class="no-uppercase"
                    dark
                    width="100px"
                    color="#13736f"
                    @click="add()"
                  >
                    <span
                      class="mainfont"
                      style="color: white; font-size: 14px"
                    >
                      Add
                    </span>
                    <v-icon size="15px" color="white">mdi-plus</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout pt-9 pb-6 wrap justify-center>
              <v-flex align-self-center>
                <div>
                  <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
                    v-model="currentPage" color="#13736f"></v-pagination>
                </div>
              </v-flex>
            </v-layout>
  </div>
</template>
    <script>
import axios from "axios";
import ImageComp from "@/components/Common/singleImage";
import moment from "moment";

export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      test: null,
      showSnackBar: false,
      deletedialog: false,

      ServerError: false,
      timeout: 5000,
      editdailog: false,
      prev: null,

      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      content: null,
      title: null,
      preview: null,

      name: "",
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      perPage: 4,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,

      list: [],
      data: [],
      curid: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      role: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,

      menu: false,
      menu1: false,
      menu2: false,
      menu22: false,

      formData2: new FormData(),

      empList2: null,
      toDate: null,
      fromDate: null,
      formData: new FormData(),
      empList3: null,
      search1: null,
      search2: null,
      search3: null,
      search11: null,
      search22: null,
      topImage: null,
      copyarray: [],
      searchInput: null,
      search33: null,
      keysearch: null,
      ffprojectList: null,
      dialog2: false,
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  computed: {},

  mounted() {
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList();
    },
 
  },

  methods: {
   
    deleteItem() {
      axios({
        url: "/admin/news/delete/" + this.curid,
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    edit() {
      this.formData2.append("id", this.curid._id);
      this.formData2.append("title", this.curid.title);
      this.formData2.append("content", this.curid.content);
      this.formData2.append("fromDate", this.curid.fromDate);
      this.formData2.append("toDate", this.curid.toDate);
      this.formData2.append("photo", this.topImage);
      axios({
        method: "POST",
        url: "/admin/news/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.formData2,
      })
        .then((response) => {
          if (response.data.status == true) {
            this.editdailog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

  

    winStepper(window_data) {
      if (window_data.type == "topImage") {
        this.topImage = window_data.selectedFiles;
      } else if (window_data.type == "image") {
        this.image = window_data.selectedFiles;
      } else if (window_data.type == "image") {
        this.imageArray = window_data.imageArray;
        this.imageFiles.push(window_data.selectedFiles);
      } else if (window_data.type == "imageRemoval") {
        var values = this.formData.getAll("photos");
        values.splice(window_data.removalItem, 1);
        this.formData.set("photos", values);
        this.imageArray = window_data.imageArray;
      }
    },
    add() {
      this.formData.append("title", this.title);
      this.formData.append("content", this.content);
      this.formData.append("photo", this.topImage);
      this.formData.append("fromDate", this.fromDate);
      this.formData.append("toDate", this.toDate);
      axios({
        method: "POST",
        url: "/admin/news/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.formData,
      })
        .then((response) => {
          if (response.data.status == true) {
            this.dialog2 = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    getList() {
      this.appLoading = true;
      axios
        .get("/news/all/list", {
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            keyword: this.keysearch,
            page: this.currentPage,
            fromDate: this.fromDate,
            toDate: this.toDate,
            limit: 10,
          },
        })
        .then((response) => {
          this.appLoading = false;
          this.list = response.data.data;
          this.copyarray = response.data.data;
          for (let i = 0; i < this.copyarray.length; i++) {
            this.copyarray[i].fromDate = moment(
              this.copyarray[i].fromDate
            ).format("YYYY-MM-DD");
            this.copyarray[i].toDate = moment(this.copyarray[i].toDate).format(
              "YYYY-MM-DD"
            );
          }
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
   
  },
};
</script>
    <style >
.justified-text {
  text-align: justify;
}
.cus {
  background-color: #13736f;
}
.no-uppercase {
  text-transform: none !important;
}
.cus2 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
</style>